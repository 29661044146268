.centerName{
    color: #0274B6;
    font-size: 20px;
  }
  
  .grayText{
    color: gray;
  }

  .help{
    color: gray !important;
  }

  .warningText{
    color: red;
  }
  
  .btn{
    border-radius: 0.5rem !important;
  }
  
  .card-row {
    border-radius: 8px !important;
  }
  .hoverRow:hover {
    background-color: #f9f9f9 !important;
    cursor: pointer !important;
  }

  .startBtn{
        color: #fff !important;
        background-color: #018503 !important;   
  }
  .statusBtn{
      color: gray !important;
      border: 2px solid lightgray !important;
      padding: 6px 20px;
  }

  .startBtn:disabled{
    cursor: not-allowed !important;
   }
   
  .card-body{
     padding: 2rem !important;
  }
  